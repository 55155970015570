import React from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { TextSm } from '@system'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  filter: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiInputLabel-shrink': {
      color: theme.palette.text.secondary,
    },
    '& .MuiFormLabel-filled': {
      display: 'none',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(12px, 6px) scale(1)',
      [theme.breakpoints.up('md')]: {
        transform: 'translate(12px, 7px) scale(1)',
      },
      [theme.breakpoints.up('lg')]: {
        transform: 'translate(12px, 8px) scale(1)',
      },
    },
  },
  filterInput: {
    height: '32px',
    color: theme.palette.text.secondary,
    '&>div': {
      paddingTop: '4px',
      paddingBottom: '4px',
      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    '&>svg': {
      fontSize: '1.5rem',
      color: theme.palette.background.slate,
    },
  },
  textField: {
    '& input': {
      padding: '7px 10px',
      height: '32px',
      boxSizing: 'border-box',
    },
  },
  option: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const GalleryFilterModule = (props) => {
  const {
    resourceFilters,
    title,
    filterType,
    onChangeSearchText,
    onChangeFilters,
    searchBox,
    filters,
    filterOptions,
  } = props.blok
  const classes = useStyles()
  const hasResourceFilters = !!resourceFilters && resourceFilters.length > 0

  const selectValue =
    (hasResourceFilters ? title.toLowerCase() : filterType.toLowerCase()) ===
    filters.filterBy.toLowerCase()
      ? filters.filterSelect
      : ''

  const fieldOptions = get(resourceFilters, '[0].fieldOptions', [])

  return (
    <SbEditable content={props.blok}>
      <FormControl variant="outlined" className={classes.filter}>
        {searchBox ? (
          <TextField
            id={title.toLowerCase()}
            placeholder={title}
            variant="outlined"
            type="text"
            className={classes.textField}
            onChange={(event) => onChangeSearchText(event)}
          />
        ) : (
          <>
            <InputLabel htmlFor={hasResourceFilters ? title : filterType}>
              <TextSm>{title}</TextSm>
            </InputLabel>
            <Select
              name={hasResourceFilters ? title : filterType}
              value={selectValue}
              onChange={(event) =>
                onChangeFilters(event, hasResourceFilters ? title : filterType)
              }
              className={classes.filterInput}
            >
              {hasResourceFilters
                ? fieldOptions.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        <TextSm className={classes.option}>{option}</TextSm>
                      </MenuItem>
                    )
                  })
                : filterOptions &&
                  filterOptions.map((option) => {
                    return (
                      <MenuItem key={option} value={option}>
                        <TextSm className={classes.option}>{option}</TextSm>
                      </MenuItem>
                    )
                  })}
            </Select>
          </>
        )}
      </FormControl>
    </SbEditable>
  )
}

export default GalleryFilterModule
